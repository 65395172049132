<template>
	<div>
		<Title title="稿件预览" :backShow2="backShow" />
		<div class="Man_content">
			<div class="Man_content_title_box">
				<h4 class="title">{{ fill_form.title }}</h4>
				<p class="hint">提示：本平台所发布信息的内容和准确性由提供消息的原单位或组织独立承担完全责任!</p>
			</div>
			<div v-if="fill_form.content" class="Man_content_text"
				v-html="$options.filters.formatTitle(content, verifyArray)">
			</div>
		</div>
		<!-- 广告的底部操作栏 -->
		<set_placard />
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import SetMenuButNav from '@/components/SetMenuButNav'
import set_placard from '@/components/set_placard'
import { mapState } from 'vuex'
import { timeCut } from '@/util/timeCut'
// import { goLink } from '@/util/goLink'
export default {
	mounted() {
		this.fill_form = this.placard.fill_form
		this.shop_car = this.placard.car_list
		this.content = JSON.parse(JSON.stringify(this.fill_form.content))
		// 效验文章是否有敏感字
		this.verifyTest()
		// console.log(this.$store.state.FillForm);
		if (this.fill_form.limit_time !== '') {
			this.limit_time = timeCut(this.fill_form.limit_time)
		}
		// 区分返回按钮
		this.backShow = 2
	},
	computed: {
		...mapState(['FillForm', 'placard', 'shopObj', 'standing']),
	},
	components: {
		Title,
		SetMenuButNav,
		set_placard
	},
	data() {
		return {
			shop_car: [],
			fill_form: {
				cover_image: '', //封面
				title: '',
				content: '', //编译器内容
				limit_time: '', //时间选择器
				file: '',
				url: '',
				video_url: '',
				original_url: '', //原文链接
				remark: '',
				medium_ids: '',
				package_ids: '',
			},
			backShow: 1,
			content: '',
			limit_time: '',
			verifyArray: [],
		}
	},
	filters: {
		formatTitle(value, keyword) {
			if (!value) return '';
			// value = value.length > 30 ? value.substr(0, 29) + '...' : value; //标题超过30字符展示...
			if (Array.isArray(keyword)) {
				keyword.forEach((item) => {
					if (item && value.includes(item)) {
						var regex = RegExp('(' + item.replace('\\$1') + ')', 'ig');
						var html = '<span style="color:red;font-size="1em"" >' + item + '</span>';
						value = value.replace(regex, html)
					}
				})
			}
			return value;
		},
	},
	methods: {
		verifyTest() {
			if (this.$store.state.FillForm.content) {
				this.curlGet('/api/index/website').then(res => {
					res.data.data.sensitive_word.forEach((item, index) => {
						this.verifyArray.push(item)
					})
				})
			}
		},

	},
}
</script>

<style lang="scss" scoped>
.title {
	.but {
		// width: 22rem !important;

		.back {
			width: 5.83rem;
			height: 2.5rem;
			background: #fff;
			color: #108CDD;
			border: 0;
			box-shadow: 0.17rem 0.17rem 0.33rem #000000;
			border-radius: 0.42rem;
			cursor: pointer;
		}

		.copy {
			width: 5.83rem;
			height: 2.8rem;
			border: 0;
			background: #108CDD;
			color: #fff;
			border: 0;
			border-radius: 0.42rem;
		}
	}
}

.Man_content {
	min-height: 100vh;
	padding: 24px 30px;
	box-sizing: border-box;
	position: relative;
	z-index: 999;
	border-radius: 0.83rem;
	background: #ffffff;
	box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;
	margin-top: 1rem;
	margin-bottom: 10rem;

	.Man_content_title_box {
		border-bottom: 0.08rem solid #aaaaaa;
		padding-bottom: 1rem;
		// margin-bottom: 1rem;
		box-sizing: border-box;

		.title {
			color: #333;
			font-size: 1.8rem;
			text-align: center;

		}
	}

	.Man_content_title {
		background: #E1F1FC;
		padding: 15px;
		box-sizing: border-box;
		color: #108CDD;
		font-size: 14px;

		span {
			cursor: pointer;
			color: #333;
			font-size: 14px;
			text-decoration: underline;
			padding-left: 5px;
		}

		i {
			font-style: normal;
			color: rgba(255, 0, 0, 1);
			font-size: 14px;

			&:first-child {
				margin-left: 10px;
			}
		}
	}

	.content_title {
		font-size: 16px;
		color: #333;
		margin: 10px 0 0 0;
	}

	.content {
		padding-top: 10px;
		box-sizing: border-box;
		font-size: 16px;
		line-height: 40px;

		::v-deep p {
			// font-size: 1.22rem !important;
			color: #333;
		}
	}
}

.fillFormTime {
	color: #EF2626;
	font-size: 1.33rem;
	font-weight: bold;
	text-align: left;
}

.hint {
	color: #EF2626;
	font-size: 1.2rem;
	text-align: center;
}

.remark_box {
	background: #E1F1FC;
	padding: 1rem 0;
	max-height: 10rem;
	overflow-y: auto;
	font-size: 1.22rem;
	box-sizing: border-box;
}

.Man_content_text {
	color: #333;
	line-height: 35px;

	::v-deep p {
		font-size: 16px !important;
		color: #333;

		strong {
			font-size: 14px !important;
			color: #333;
		}
	}

}

.out_text {
	font-size: 14px;
	margin-bottom: 10px;
	align-items: center;

	span {
		color: #333;
	}

	a {
		display: block;
		width: 101px;
		height: 32px;
		line-height: 32px;
		border-radius: 10px;
		background: #0FA30F;
		color: #fff;
		text-decoration: none;
		text-align: center;
		margin-left: 10px;
	}
}
</style>