<template>
	<div class="but_shop_box flex" v-loading.fullscreen.lock="fullscreenLoading" v-if="placard.car_list.length">
		<!-- {{ content }} -->
		<!-- v-if="placard.car_list.length != 0" -->
		<div class="shop_length">
			<p class="shop_length_title" @click.stop="shopFn">
				已选-资源列表: {{ placard.car_list.length }}
			</p>
			<div class="shop_particulars_box" v-show="shopShow">
				<div class="shop_particulars_ct_box flex">
					<p>已选资源 {{ placard.car_list.length }}个</p>
					<p>总金额:￥{{ total_money }}元</p>
				</div>
				<!-- <img :src=`../${img}` alt=""> -->
				<ul class="shop_list">
					<li v-for="(item, index) in placard.car_list" :key="index" class="flex" style="position: relative;">
						<p class="title flex">
							<span class="title_name">{{ item.title }}</span>
							<span class="price">￥{{ item.member_guanggao_price[item.type] }}元/{{ cycle_list[item.type]
								}}共{{ item.cycle }}期{{ item.check ? `/[设计价格:￥${item.design_price}]` : '' }}</span>
						</p>
						<i class="delete" @click.stop="deleteFn(item)"></i>
					</li>
				</ul>
				<div class="flex" style="justify-content: flex-end">
					<button class="close" @click.stop="shopShow = false">关闭</button>
				</div>
			</div>
		</div>
		<!-- v-if="placard.car_list.length != 0" -->
		<div class="price_box flex">
			<p class="money" style="margin-right: 20px;">
				账户余额: <span>￥{{ $user_info.guanggao_money }}</span>元
			</p>
			<p class="price">
				总价: <span>￥{{ total_money }}</span>元
			</p>

			<button v-if="$route.name == 'placard_fill'" class=" preview" @click.stop="subFn1">
				预览文章并发布
			</button>
			<button v-if="$route.name == 'placard_manuscript_preview'" class="sub" @click.stop="subFn2">
				确认下单
			</button>
			<!-- <button v-if="$route.name == 'ManuscriptDetails'" class="sub" @click.stop="subFn3">
				立即支付
			</button> -->
		</div>
		<!-- <div class="shop_length" v-if="placard.car_list.length == 0">

		</div> -->
		<!-- 遮罩层 -->
		<div class="MaskLayer" @click="shopShow = !shopShow" v-show="shopShow"></div>
		<sensitivityPopup @close="close" :visible="popupVisible" :content="popupContent" :type="2" />
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import sensitivityPopup from '@/components/sensitivityPopup.vue'
import { fill_title_rules } from '@/util/fill_title_rules'
export default {
	name: '',
	computed: {
		...mapState(['placard']),
		...mapMutations('placard',['DELETE_LIST', 'RESET_FILL_FORM','SET_ORDER_STATUS']),
		total_money: function () {
			var n = 0;
			this.placard.car_list.forEach((item, index) => {
				if (item.check) {
					n += (item.member_guanggao_price[item.type] * item.cycle) + parseInt(item.design_price)
				} else {
					n += item.member_guanggao_price[item.type] * item.cycle
				}
			})
			return n.toFixed(2)
		},
	},
	components: {
		sensitivityPopup
	},
	data() {
		return {
			shopShow: false,
			fullscreenLoading: false,
			popupVisible: false,
			popupContent: [],
			cycle_list: ['天', '周', '月', '次', '期']
		}
	},
	methods: {
		// 更新用户信息
		setUserInfo() {
			this.curlGet('/api/user/info').then((res) => {
				if (res.data.code) {
					this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
					this.$user_info = res.data.data
					console.log(this.$user_info);
				}
			})
		},
		shopFn() {
			this.shopShow = true
		},
		// 预览
		subFn1() {
			// 效验标题状态
			const titleStatus = fill_title_rules(this.placard.fill_form.title)
			if (!titleStatus) {
				return
			}
			if (this.placard.fill_form.content == '') {
				return this.$message({
					type: 'warning',
					message: '内容不能为空',
				})
			}
			this.verifyTest()
		},
		subFn2() {
			if (this.$user_info.guanggao_money - this.total_money < 0) {
				// this.$message({
				// 	type: 'warning',
				// 	message: '余额不足,请充值',
				// })
				this.$alert('<p style="color: #333;font-size: 1.1rem;">余额不足,<a style="color: #108cdd;font-weight: bold;text-decoration: none;" href="https://www.qixuantong.com/user/refill" target="_blank">点击跳转至充值</a></p><p>充值完成后可返回此页面继续提交订单</p>', '提示', {
					dangerouslyUseHTMLString: true,
					showCancelButton: true,
					confirmButtonText: '确认充值完成'
				}).then(res => {
					this.setUserInfo()
				})
			} else {
				this.fullscreenLoading = true
				let data = {}
				data.info_ids = []
				this.placard.car_list.forEach((item, index) => {
					console.log(item);
					data.info_ids.push({
						id: item.id,
						type: item.type,
						design_price: item.check ? item.design_price : 0,
						cycle: item.cycle,
					})
				})
				data.category_id = this.placard.car_list[0].category_id
				data.info_ids = JSON.stringify(data.info_ids)
				data.title = this.placard.fill_form.title
				data.content = this.placard.fill_form.content
				data.file = this.placard.fill_form.file
				this.curlPost('/api/users/guanggao_order/save', data).then((res) => {
					if (res.data.code) {
						// 更新用户数据
						this.setUserInfo()
						this.$message({
							type: 'success',
							message: '支付成功',
						})
						this.$store.state.menu_active = '/placard_user/placard_order'
						this.$store.commit('RESET_FILL_FORM')
						this.$store.commit('SET_ORDER_STATUS',0)
						this.$router.push('/placard_user/placard_order')
					} else {
						this.$message({
							type: 'warning',
							message: res.data.msg,
						})
					}
				})
				this.fullscreenLoading = false


			}
		},
		// 删除
		deleteFn(item) {
			this.$store.commit('DELETE_LIST', item)
		},
		// 关闭敏感词弹窗
		close() {
			this.popupVisible = false;
		},
		// 效验文章是否有敏感字
		verifyTest() {
			this.fullscreenLoading = true
			this.popupContent = []
			if (this.placard.fill_form.content) {
				this.curlGet('/api/index/website').then(res => {
					res.data.data.sensitive_word.forEach((item, index) => {
						if (this.placard.fill_form.content.indexOf(item) > 0) {
							this.fullscreenLoading = false
							this.popupContent.push(item)
							return this.popupVisible = true
						}
					})
				})
			}
			setTimeout(() => {
				this.fullscreenLoading = false
				if (this.popupContent.length == 0) {
					this.$router.push('/placard_user/placard_manuscript_preview')
				}
			}, 800)
		},
	},

}
</script>

<style lang=scss scoped>
@import '@/scss/fill_set'
</style>